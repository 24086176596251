import React from "react";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch } from "../../../store/hooks";
import TicketPaymentForm from "./components/TicketPaymentForm";

interface TicketPaymentProcessProps {
  handleNextStep: () => void;
}

const initialFormData = {
  ticket_count: 0,
  card: "",
  expiry: "",
  cvc: "",
};

export default function TicketPaymentProcess({ handleNextStep }: TicketPaymentProcessProps) {
  const handleRouteClick = useHandleRouteClick();

  const handleSubmit = () => {
    // toast.success("Successfully submitted.");
    handleNextStep();
  };

  return (
    <div>
      <div className="my-3 d-flex justify-content-between">
        <div>
          <a href={routes.home.path}>
            <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
              Home
            </h4>
          </a>
        </div>
        <div>
          <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
            Log Out
          </h4>
        </div>
      </div>
      <TicketPaymentForm handleSubmit={handleSubmit} />
    </div>
  );
}
