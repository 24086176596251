import React from "react";
import Input from "react-phone-number-input/input";
import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import BGVideo from "../../components/BGVideo";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { getNominators, register } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import styles from "../../styles/RegisterPage.module.scss";
import EventDetailModal from "./components/EventDetailModal";

const initialFormData = {
  first_name: "",
  last_name: "",
  full_name: "",
  username: "",
  email: "",
  mobile: "",
  // address: "",
  nominated_by: "",
  // company: "",
  industry: "",
  position: "",
  country: "",
  password: "",
  confirmPassword: "",
};

export default function RegisterPage() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const handleRouteClick = useHandleRouteClick();
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const [formData, setFormData] = React.useState(initialFormData);
  const [nominators, setNominators] = React.useState<Record<string, any>>([]);
  const [openEventDetailModal, setOpenEventDetailModal] = React.useState(false);

  const toggleOpenEventDetailModal = () => {
    setOpenEventDetailModal((prev) => !prev);
  };

  React.useEffect(() => {
    dispatch(getNominators())
      .then((res) => {
        if (res.payload.status === 200) {
          const data = res.payload.data;
          setNominators(data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRegister = () => {
    console.log("submit...");
    if (formData.password !== formData.confirmPassword) {
      toast.error("Both passwords are not same.");
      return;
    }
    dispatch(
      register({
        ...formData,
        full_name: `${formData.first_name} ${formData.last_name}`,
        username: formData.username.toLowerCase(),
        ref_code: searchParams.get("ref_code"),
      })
    )
      .then((res) => {
        if (res?.payload?.status === 201) {
          toast.success("Successfully registered.");
          handleRouteClick(routes.login.path);
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <div className="container-fluid px-0 text-black">
      <BGVideo />
      <div
        className={`p-4 d-flex flex-column bg-white-transparent border border-dark mb-5 mb-md-auto ${styles["page-curve"]}  ${styles["white-box"]}`}
      >
        <div className="d-flex justify-content-center align-items-center position-relative">
          <p className="text-center font-graebenbach fw-bold my-auto">{searchParams.get("ref_code") ? "GUEST SIGNUP" : "SIGN UP"}</p>
          <div className="position-absolute start-0">
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.home.path)} style={{ cursor: "pointer", textAlign: "right" }}>
              Home
            </h4>
          </div>
        </div>
        <div className="mb-4 text-center">
          {searchParams.get("ref_code") && (
            <a
              href="#"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#event_detail_moda"
              onClick={toggleOpenEventDetailModal}
              className="font-graebenbach text-decoration-underline px-4 py-1 mb-5 mb-md-3 text-black"
            >
              Event Details
            </a>
          )}
        </div>
        <div className="h-100 center" style={{ flexGrow: 1 }}>
          <div className={`${styles.form} my-5 my-md-1 mx-4`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleRegister();
              }}
            >
              <div className="container">
                {/* Desktop */}
                <div className="row mb-md-5 g-5">
                  <div className="col-12 col-md-4">
                    <div className="row g-5">
                      <div className="col-12">
                        <select
                          className="w-100"
                          onChange={handleSelectChange}
                          name="nominated_by"
                          required
                          id="nominated_by"
                          placeholder="Nominated By *"
                        >
                          <option value="">Nominated By *</option>
                          {nominators.map((n: Record<string, any>) => {
                            return <option value={n.id}>{n.name}</option>;
                          })}
                        </select>
                      </div>
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="first_name" required type="text" placeholder="First Name *" />
                      </div>
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="last_name" required type="text" placeholder="Last Name *" />
                      </div>
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="email" required type="email" placeholder="Email *" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="row g-5">
                      <div className="col-12">
                        <Input
                          name="mobile"
                          required={true}
                          type="tel"
                          placeholder="Phone *"
                          defaultCountry="US"
                          value={formData.mobile}
                          className=""
                          onChange={(value) => {
                            setFormData((prev) => ({
                              ...prev,
                              mobile: String(value),
                            }));
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="username" required type="text" placeholder="Username *" />
                      </div>
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="password" required type="password" placeholder="Password *" />
                      </div>
                      <div className="col-12">
                        <input
                          className=""
                          onChange={handleInputChange}
                          name="confirmPassword"
                          required
                          type="password"
                          placeholder="Confirm Password *"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="row g-5">
                      <div className="col-12">
                        <input className="" onChange={handleInputChange} name="country" type="text" placeholder="Country (Optional)" />
                      </div>
                      <div className="col-12">
                        <input
                          className=""
                          onChange={(e) => {
                            const prefix = "@";
                            const currentValue = e.target.value;
                            if (!currentValue.startsWith(prefix)) {
                              e.target.value = prefix + currentValue;
                            }
                            handleInputChange(e);
                          }}
                          name="instagram"
                          type="text"
                          placeholder="Instagram (Optional)"
                        />
                      </div>
                      <div className="col-12">
                        <input
                          className=""
                          onChange={handleInputChange}
                          name="industry"
                          required
                          type="text"
                          placeholder="Industry (N/A if none) *"
                        />
                      </div>
                      <div className="col-12">
                        <input
                          className=""
                          onChange={handleInputChange}
                          name="position"
                          required
                          type="text"
                          placeholder="Position (N/A if none) *"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-4">
                    <input className="" onChange={handleInputChange} name="company" required type="text" placeholder="Company (N/A if none) *" />
                  </div> */}
                  {/* <div className="col-12 col-md-4">
                  <input className="" onChange={handleInputChange} name="address" type="text" placeholder="Address" />
                </div> */}
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center">
                <EventDetailModal open={openEventDetailModal} toggleModal={toggleOpenEventDetailModal} />
                <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-3 text-black">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
